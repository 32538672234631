<template>
  <div class="sidebar">
      <el-menu class="sidebar-el-menu" :default-active="onRoutes" background-color="#FFFFFF"
          text-color="#333333" active-text-color="#333333" unique-opened router @select="selectMenu">
          <template v-for="(item, index) in items">
              <template v-if="item.children">
                  <el-submenu :index="item.path" :key="item.path">
                      <template slot="title">
                        <div class="flexAO">
                          <span class="iconfont" :class="item.icon"  style="font-size:24px;margin-right: 15px;" ></span>
                          <span>{{ item.name }}</span>
                        </div>
                      </template>
                      <template v-for="(subItem, eq) in item.children">
                          <template v-if="subItem.power">
                              <el-menu-item :index="subItem.path" :key="subItem.path" @click="ItemClick(index, eq)">{{ subItem.name }}</el-menu-item>
                          </template>
                      </template>
                  </el-submenu>
              </template>
              <template v-else>
                  <el-menu-item :index="item.path" :key="item.path" @click="ItemClick(index)">
                      <div class="flexAO">
                        <span class="iconfont" :class="item.icon" style="font-size:24px;margin-right: 15px;"></span>
                        <span>{{ item.name }}</span>
                      </div>
                  </el-menu-item>
              </template>
          </template>
      </el-menu>
  </div>
</template>

<script>
var Base64 = require('js-base64').Base64
export default {
  data () {
    return {
      items: [],
      checkedMenu: '',
      roleBtn: {}
    }
  },
  computed: {
    onRoutes () {
      return this.$route.path.replace('/', '')
    }
  },
  created () {
    this.checkedMenu = 'management'
    const _relepers = JSON.parse(window.localStorage.getItem('user')).relepers
    const items = JSON.parse(Base64.decode(_relepers))
    // console.log(items)
    this.items = this.screenMenu(items)
  },
  methods: {
    selectMenu (index) {
      this.checkedMenu = index
    },
    ItemClick (index, eq) {
      this.$emit('GetLabel', this.getMenuLabel(this.items[index], eq))
    },
    // 获取导航标签
    getMenuLabel (item, si) {
      let btnGroup = []
      let label = ''
      let seLabel = null
      let path = null
      if (item.level === true && item.power === true) {
        if (si === undefined || si === null) {
          label = item.name
          seLabel = this.selectArray(item.children).name
          btnGroup = this.selectArray(item.children).meta
          path = this.selectArray(item.children).path
        } else {
          label = item.name
          seLabel = item.children[si].name
          btnGroup = item.children[si].meta
          path = item.children[si].path
        }
      } else if (item.level === false && item.power === true) {
        label = item.name
        seLabel = null
        btnGroup = item.meta
        path = item.path
      }
      return { label: label, seLabel: seLabel, path: path, btnGroup: btnGroup }
    },
    selectArray (arr) {
      var brr = []
      arr.forEach((item) => {
        if (item.power) {
          brr.push(item)
        }
      })
      return brr[0]
    },
    screenMenu (list) {
      var arr = []
      list.forEach(item1 => {
        if (item1.level) {
          if (item1.power) {
            var brr = []
            item1.children.forEach(item2 => {
              if (item2.power) {
                brr.push(item2)
              }
            })
            if (brr.length > 0) {
              arr.push(item1)
            }
          }
        } else {
          if (item1.power) {
            arr.push(item1)
          }
        }
      })
      return arr
    }
  }
}
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 60px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
}
.sidebar .el-menu--inline .el-menu-item {
  padding-left: 70px !important;
}
.el-menu-item {
  height: 60px !important;
  line-height: 60px !important;
  font-size: 16px !important;
  color: #576271 !important;
}
.el-submenu >>> .el-submenu__title {
  font-size: 16px !important;
  color: #576271 !important;
}
.el-submenu >>>.el-submenu__title:hover, .el-menu-item:focus, .el-menu-item:hover {
  background-color: #F7F9FB !important;
}
/*.el-menu-item.is-active{*/
/*  background-color: #E7EEFF !important;*/
/*}*/
.el-menu-item.is-active .iconfont,.el-menu-item.is-active .flexAO,.sidebar .el-menu--inline .el-menu-item.is-active{
  color: #3C84EF !important;
}
</style>
