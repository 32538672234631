<template>
  <el-container class="home-container">
    <el-header>
      <div class="head_left flexAC">
        <div class="head_user">
          <img src="../assets/img/logo.png" alt="">
        </div>
        <div class="system_name">
          <p class="name_one">驭臣后台管理系统</p>
          <p class="name_two">{{rolename}}</p>
        </div>
      </div>
      <div class="head_right flexAC">
        <div>
          <el-popover placement="bottom" width="420" trigger="manual" v-model="poShow">
            <i class="right_icon iconfont icon-horn" slot="reference" @click="poShow = !poShow">
              <span class="TAN" v-if="makeNum + refundNum > 0">{{ makeNum + refundNum }}</span>
            </i>
            <!-- 头部选项 -->
            <el-menu
              mode="horizontal"
              active-text-color="#3C84EF"
              :default-active="activeIndex"
              @select="handleSelect"
              class="el-menu-demo flexSC">
              <el-menu-item index="1">
                <i class="iconfont icon-msgyuy"></i><span class="T1N" v-if="makeNum > 0">{{ makeNum }}</span>
              </el-menu-item>
              <el-menu-item index="2">
                <i class="iconfont icon-msgtk"></i><span class="T1N" v-if="refundNum > 0">{{ refundNum }}</span>
              </el-menu-item>
            </el-menu>
            <!-- 消息内容 循环部分 -->
            <div class="infobox">
              <div class="notification_text flexCB" v-for="(item, index) in msgList" :key="index" @click="goDetail(item)">
                <div class="text_info">
                  <p class="text_b">{{ item.creatime }}</p>
                  <div>
                    <span>{{item.megdata.other}}</span>
                    <span v-if="item.megdata.type == 1" style="color: #3C84EF;">{{item.megdata.type_name}},</span>
                    <span v-if="item.megdata.type == 2" style="color: #F44C8E;">{{item.megdata.type_name}},</span>
                    <span v-if="item.megdata.type == 3" style="color: #44B557;">{{item.megdata.type_name}},</span>
                    <span v-if="item.megdata.type == 4" style="color: #3C84EF;">{{item.megdata.type_name}},</span>
                    <span v-if="item.megdata.type == 5" style="color: #F44C8E;">{{item.megdata.type_name}},</span>
                    <span>{{item.megdata.mark}}</span>
                  </div>
                </div>
                <div class="cicle" v-if="item.mesisok === 0"></div>
              </div>
              <div v-if="msgList.length === 0" style="text-align: center;margin-top: 45%;">暂无数据</div>
            </div>
            <!-- 底部按钮部分 -->
            <div class="bottom_button flexCB">
              <span class="bottom_button_left" @click="handRead">全部标记为已读</span>
              <span class="bottom_button_right" @click="handMeg">查看全部</span>
            </div>
          </el-popover>
        </div>
        <!--  消息提示  -->
        <template v-if="makeNum + refundNum > 0">
          <audio ref="audio" class="audio" src="../assets/msg.mp3" controls autoplay="autoplay" :hidden="true"></audio>
        </template>
        <div class="flexCE right_user">
          <el-dropdown>
            <div class="hr_item">
              <img :src="furl" alt="" class="item_img">
              <span class="hr_itName">{{username}}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="handleUser"><span>个人信息</span> </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div @click="handlePwd"><span>修改密码</span> </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div @click="loginOut"><span>退出登录</span> </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </el-header>
    <!--  主体内容  -->
    <el-container>
      <el-aside style="width: 250px;">
        <Sidebar @GetLabel="GetLabel"></Sidebar>
      </el-aside>
      <el-main class="setting_main" style="padding: 8px;">
        <div class="setting_name">
          <span>{{ title }}</span>
          <span v-if="twoTitle != null">/{{twoTitle}}</span>
        </div>
        <div class="setting_cont">
          <router-view :roleBtn="roleBtn" @handMsgRead="handMsgRead" @handMsgBack="handMsgBack"></router-view>
        </div>
      </el-main>
    </el-container>

    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="showPwd" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="pwdCancel">
      <el-form :model="pwdfrom" :rules="formRules" ref="pwdFrom">
        <el-form-item label="旧密码"  prop="old_password">
          <el-input type="password" v-model="pwdfrom.old_password"  placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码"  prop="new_password">
          <el-input type="password" v-model="pwdfrom.new_password"  placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码"  prop="new_password2">
          <el-input type="password" v-model="pwdfrom.new_password2"  placeholder="请再次输入新密码"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="pwdCancel">取消</div>
        <div class="settingConfirm" @click="pwdFromConfirm">确定</div>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import Sidebar from '@/components/sidebar.vue'
import { staffPs } from '@/api/staff.js'
import { mesQuery, mesRead, msgIdRead } from '@/api/system'
import sounds from '@/assets/msg.mp3'
var Base64 = require('js-base64').Base64
export default {
  components: {
    Sidebar
  },
  data () {
    const validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入新密码'))
      } else if (value.toString().length < 6 || value.toString().length > 18) {
        callback(new Error('密码长度为6-18位'))
      } else if (value === this.pwdfrom.old_password) {
        callback(new Error('新密码不能与原密码相同!'))
      } else {
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.pwdfrom.new_password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      haveMsg: false,
      sound: sounds,
      username: '',
      showPwd: false,
      pwdfrom: {
        old_password: '',
        new_password: '',
        new_password2: ''
      },
      formRules: {
        old_password: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        new_password: [{ required: true, validator: validatePass, trigger: 'blur' }],
        new_password2: [{ required: true, validator: validatePass2, trigger: 'blur' }]
      },
      userShow: false,
      activeIndex: '1',
      rolename: '',
      staffid: '',
      megtype: 1,
      msgList: [],
      makeNum: 0,
      refundNum: 0,
      roleBtn: [],
      title: '', // 标题
      twoTitle: '', // 二级标题
      timer: null,
      isPlaying: false,
      poShow: false,
      msgGoType: null, // 监听消息是从[其他页面]或者[消息页面]进入[预约or订单]
      changeMainTitle: '', // 消息跳转页面变化，监听的动态一级标题
      changeSubTitle: '', // 消息跳转页面变化，监听的动态二级标题
      // audio: ''
    }
  },
  directives: {
    trigger: {
      inserted (el, binging) {
        // console.log(el.id)
        el.id === 'PlayBox' ? el.click() : null
      }
    }
  },
  watch: {
    roleBtn (val) {
      this.roleBtn = val
      // console.log(this.roleBtn)
    }
  },
  created () {
    this.username = JSON.parse(window.localStorage.getItem('user')).sname
    this.rolename = JSON.parse(window.localStorage.getItem('user')).rolename
    this.staffid = JSON.parse(window.localStorage.getItem('user')).staffid
    this.furl = JSON.parse(window.localStorage.getItem('user')).furl

    const _relepers = JSON.parse(window.localStorage.getItem('user')).relepers
    const _menus = JSON.parse(Base64.decode(_relepers))
    const pageRoute = this.getMenuLabel(this.selectArray(_menus))
    this.$router.push({ path: '/' + pageRoute.path })
    this.title = pageRoute.label
    this.twoTitle = pageRoute.seLabel
    this.roleBtn = pageRoute.btnGroup
    // console.log(this.roleBtn)
    this.changeMainTitle = this.title
    this.changeSubTitle = this.twoTitle

    this.getMesNumberPlay()
    clearInterval(this.timer)
    this.timer = null
    this.setTimer()
  },
  methods: {
    setTimer () {
      if (this.timer == null) {
        this.timer = setInterval(() => {
          this.getMesNumberPlay()
        }, 120000)
      }
    },
    /* 点击左侧菜单 */
    GetLabel (res) {
      this.title = res.label
      this.twoTitle = res.seLabel
      this.roleBtn = res.btnGroup
      this.changeMainTitle = this.title
      this.changeSubTitle = this.twoTitle
    },
    /* 获取消息 */
    getMesNumberPlay () {
      mesQuery({ megtype: this.megtype }).then(res => {
        if (res.status === 1) {
          this.makeNum = res.data.count[0]
          this.refundNum = res.data.count[1]
          const _result = []
          if (res.data.list.length > 0) {
            res.data.list.forEach((item) => {
              if (item.mesisok === 0) {
                _result.push(item)
              }
            })
          }
          this.msgList = _result
          this.$nextTick(() => {
            if (Number(this.makeNum) + Number(this.refundNum) > 0) {
              this.palyVideo()
            }
          })
        }
      })
    },
    /* 点击单条消息-刷新未读数据 */
    handMsgRead (res) {
      this.msgGoType = 'messagePage'
      if (res === '1') { // 预约标题
        this.title = '前台管理'
        this.twoTitle = '预约管理'
      } else { // 退款管理标题
        this.title = '售后管理'
        this.twoTitle = '退款管理'
      }
      this.MesNumber()
    },
    /* 进入预约详情，订单详情-的返回 */
    handMsgBack () {
      if (this.msgGoType === 'messagePage') {
        this.title = '消息通知'
        this.twoTitle = null
      } else {
        this.title = this.changeMainTitle
        this.twoTitle = this.changeSubTitle
      }
    },
    /* 点击单条消息 */
    goDetail (item) {
      this.poShow = !this.poShow
      this.msgGoType = 'otherPage'
      msgIdRead({ msgid: item.msgid }).then(res => {
        if (res.status === 1) {
          this.MesNumber()
          if (item.megtype === 1) { /* 跳转预约 */
            this.title = '前台管理'
            this.twoTitle = '预约管理'
            this.$router.push({
              name: 'receptionOrder',
              params: {
                megasid: item.megasid
              }
            })
          } else if (item.megtype === 2) { /* 跳转订单 */
            this.title = '售后管理'
            this.twoTitle = '退款管理'
            this.$router.push({
              name: 'afterRefund',
              params: {
                megasid: item.megasid
              }
            })
          }
        }
      })
    },
    /* 点击-查看全部 */
    handMeg () {
      this.poShow = !this.poShow
      setTimeout(() => {
        this.$router.push({ path: '/message?type=' + this.megtype })
        this.title = '消息通知'
        this.twoTitle = null
      })
    },
    /* 点击-全部标记为已读 */
    handRead () {
      mesRead({ megtype: this.megtype }).then(res => {
        if (res.status === 1) {
          this.MesNumber()
        }
      })
    },
    /* 预约-退款消息切换 */
    handleSelect (e) {
      this.megtype = e
      this.MesNumber()
    },
    MesNumber () {
      mesQuery({ megtype: this.megtype }).then(res => {
        if (res.status === 1) {
          this.makeNum = res.data.count[0]
          this.refundNum = res.data.count[1]
          const _result = []
          if (res.data.list.length > 0) {
            res.data.list.forEach((item) => {
              if (item.mesisok === 0) {
                _result.push(item)
              }
            })
          }
          this.msgList = _result
        }
      })
    },
    /* 退出登录 */
    loginOut () {
      this.$router.push({ path: '/login' })
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')
      window.sessionStorage.setItem('State', '0')
      this.$store.commit('setLoginState', !this.$store.getters.loginState)
    },
    // 修改密码-展示
    handlePwd () {
      this.showPwd = true
    },
    // 修改密码-关闭
    pwdCancel () {
      this.showPwd = false
    },
    // 修改密码-提交
    pwdFromConfirm () {
      this.$refs.pwdFrom.validate((valid) => {
        if (!valid) return
        const SM4 = require('gm-crypt').sm4
        const sm4Config = {
          // 配置sm4参数
          key: 'EB0AA4FE11EC99B6',
          mode: 'ecb',
          cipherType: 'base64'
        }
        const sm4 = new SM4(sm4Config)
        const Pwd = sm4.encrypt(this.pwdfrom.old_password)
        const newPwd = sm4.encrypt(this.pwdfrom.new_password)
        const params = {
          old_password: Pwd,
          new_password: newPwd
        }
        staffPs(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            setTimeout(() => {
              this.loginOut()
            }, 1000)
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 个人信息
    handleUser () {
      this.$router.push({ path: '/userinfo' })
      this.title = '个人信息'
    },

    // 获取导航标签
    getMenuLabel (item, si) {
      let btnGroup = []
      let label = ''
      let seLabel = null
      let path = null
      if (item.level === true && item.power === true) {
        if (si === undefined || si === null) {
          label = item.name
          seLabel = this.selectArray(item.children).name
          btnGroup = this.selectArray(item.children).meta
          path = this.selectArray(item.children).path
        } else {
          label = item.name
          seLabel = item.children[si].name
          btnGroup = item.children[si].meta
          path = item.children[si].path
        }
      } else if (item.level === false && item.power === true) {
        label = item.name
        seLabel = null
        btnGroup = item.meta
        path = item.path
      }
      return { label: label, seLabel: seLabel, path: path, btnGroup: btnGroup }
    },
    selectArray (arr) {
      var brr = []
      arr.forEach((item) => {
        if (item.power) {
          brr.push(item)
        }
      })
      return brr[0]
    },
    /* 音频播放 */
    palyVideo () {
      this.$refs.audio.play()
      this.$notify({
        title: '',
        dangerouslyUseHTMLString: true,
        message: '<div class="message_dialog">' +
          '<p class="msg_title">您有新的工单，请注意查收</p>' +
          '</div>',
        position: 'bottom-right'
      })
    }
  },
  destroyed () {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>

<style scoped>
.home-container {
  width: 100%;
  height: 100%;
  background: #F7F9FB;
  display: flex;
}
.el-header {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  background: #FFFFFF;
}
.head_left {
  padding: 10px 0px 10px 20px;
  box-sizing: border-box;
  background: #3C84EF;
  width: 250px;
}
.head_user {
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 50%;
}
.head_user img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.system_name {
  font-family: 'PingFang SC';
  font-weight: bold;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 8px;
}
.name_one {
  font-size: 18px;
}
.name_two {
  font-size: 12px;
}
.head_right {
  font-size: 16px;
  font-family: 'PingFang SC';
  font-weight: 400;
  line-height: 23px;
  background: #FFFFFF;
  opacity: 1;
  cursor: pointer;
}
.el-main {
  position: absolute;
  top: 60px;
  left: 250px;
  right: 0;
  bottom: 0;
  background: #F7F9FB;
}
.right_icon {
  margin-right: 60px;
  color: #576271;
  font-size: 18px;
}
.right_user {
  margin-right: 60px;
}
.hr_item{
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #333333;
}
.item_img{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}
/* 消息部分 */
.el-menu-demo .el-menu-item:nth-child(2){
  border-right: none;
}
.el-menu-demo .el-menu-item,
.el-submenu__title {
  height: 40px;
  line-height: 30px;
  border-bottom: none;
}
.el-menu-item i {
  font-size: 20px;
}
.el-menu-item {
  width: 50%;
  text-align: center;
}
.el-menu-demo :nth-child(2) {
  border-right: #EBF2FE 1px solid;
  border-left: #EBF2FE 1px solid;
}
.T1N,.TAN{
  width: auto;
  height: 15px;
  line-height: 14px;
  background: #F44C8E;
  color: #fff;
  position: absolute;
  top: 0;
  border-radius: 10px;
  padding: 0 6px;
  font-size: 12px;
}
.TAN{
  top: 10px;
}
.bottom_button {
  width: 445px;
  height: 40px;
  border-top: #EBF2FE 1px solid;
  position: absolute;
  bottom: 0;
  left: 0;
}
.bottom_button_left,
.bottom_button_right {
  display: inline-block;
  width: 164px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.bottom_button_left:hover {
  color: #40aaf7;
}
.bottom_button_right:hover {
  color: #40aaf7;
}
.notification_text {
  width: 100%;
  /*height: 80px;*/
  border-bottom: 1px #EBF2FE solid;
  position: relative;
  padding: 10px 0px;
  box-sizing: border-box;
  cursor: pointer;
}
.text_info {

}
.text_info >>> .text_t {
  font-size: 14px;
  font-family: 'PingFang SC-Bold, PingFang SC';
  font-weight: bold;
  color: #333333;
}
.text_b {
  font-size: 12px;
  font-family: 'PingFang SC-Regular, PingFang SC';
  font-weight: 400;
  color: #A5B7CC;
  margin-bottom: 5px;
}
.cicle {
  width: 10px;
  height: 10px;
  background: #F44C8E;
  border-radius: 50%;
}
.infobox {
  height: 400px;
  margin-bottom: 37px;
  overflow-y: auto;
  scrollbar-width: none;
}
.infobox::-webkit-scrollbar{
  width: 0;
}
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #A5B7CC;
  border-radius: 3px;
}
</style>
